import React, { useEffect, useState } from "react";
import "./App.css";
import "./assets/css/main.css";
import "./assets/css/styles.css";
import "./assets/css/ande.css";
import "./assets/css/cookie.css";
import "./assets/css/static.css";
import Router from "./config/Router";
import { web3Modal } from "./config/walletConfig";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import useReferralLink from "./hooks/useReferralLink";
import useFetchInvestorDashboard from "./hooks/useFetchInvestorDashboard";
import useFetchAllReferralRewards from "./hooks/useFetchAllReferralRewards";
import { debounce } from "./utils/debounce";
import authenticateUser from "./authenticateUser"; // Import the authenticateUser function

function App() {
  const [isWeb3ModalReady, setWeb3ModalReady] = useState(false);
  const { address, isConnected } = useWeb3ModalAccount();
  const { referralLink, error, loading } = useReferralLink(
    address,
    isConnected
  );
  const { fetchInvestorDashboard } = useFetchInvestorDashboard();
  const { allRewards, fetchReferralRewards } = useFetchAllReferralRewards();

  useEffect(() => {
    try {
      if (web3Modal) {
        setWeb3ModalReady(true);
      }
    } catch (error) {
      console.error("Failed to initialize web3Modal", error);
    }
  }, []);

  useEffect(() => {
    const saveScrollPosition = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("beforeunload", saveScrollPosition);

    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, []);

  // Fetch the JWT token on page load
  useEffect(() => {
    if (address) {
      authenticateUser(address);
    }
  }, [address]);

  useEffect(() => {
    if (isConnected && referralLink) {
      debounce(fetchInvestorDashboard, 1000)();
    } else if (error) {
      console.error("Error managing referral link:", error);
    }
  }, [isConnected, address, referralLink, error, fetchInvestorDashboard]);

  if (!isWeb3ModalReady) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
