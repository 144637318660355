import React, { useState, useEffect } from "react";
import Eth from "../../../../assets/img/eth-icon.svg";
import Plg from "../../../../assets/img/plg-icon.svg";
import Avax from "../../../../assets/img/avax-icon.svg";
import Bnb from "../../../../assets/img/bsc-icon.svg";
import Arbi from "../../../../assets/img/arbitrum-icon.svg";
import Opt from "../../../../assets/img/optimism-icon.svg";
import Base from "../../../../assets/img/base-icon.svg";
import Zksync from "../../../../assets/img/zksync-2.svg";
import { ethers } from "ethers";

import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";

export default function NetworkSelector(props) {
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const networks = {
    1: { name: "ETH", icon: Eth },
    56: { name: "BNB", icon: Bnb },
    137: { name: "PLG", icon: Plg },
    42161: { name: "ARB", icon: Arbi },
    43114: { name: "AVAX", icon: Avax },
    10: { name: "OP", icon: Opt },
    8453: { name: "BASE", icon: Base },
    324: { name: "ZKsync", icon: Zksync },
  };

  const [networkData, setNetworkData] = useState(() => {
    const storedChainId = localStorage.getItem("lastConnectedNetwork");
    return storedChainId && networks[storedChainId]
      ? networks[storedChainId]
      : { name: "ETH", icon: Eth };
  });

  useEffect(() => {
    if (isConnected && networks[chainId]) {
      localStorage.setItem("lastConnectedNetwork", chainId);
      setNetworkData({
        name: networks[chainId].name,
        icon: networks[chainId].icon,
      });
    }
  }, [chainId, isConnected]);

  useEffect(() => {
    const handleConnect = async () => {
      if (localStorage.getItem("pendingNetworkSwitch") === "true") {
        localStorage.removeItem("pendingNetworkSwitch");
        setTimeout(async () => {
          await open({ view: "Networks" });
        }, 250);
      }
    };

    if (isConnected) {
      handleConnect();
    }
  }, [isConnected, open]);

  const handleClick = async () => {
    if (!isConnected) {
      localStorage.setItem("pendingNetworkSwitch", "true");
      try {
        await open();
      } catch (error) {
        console.error("Error connecting to wallet:", error);
      }
    } else {
      await open({ view: "Networks" });
    }
  };

  return (
    <>
      <div style={{ marginTop: "24px" }} className="css-xqm7c5">
        <div className="bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none bl-text-primary h-[40px] laptop:h-[44px] bg-[#44403C] flex justify-center gap-[8px] items-center">
          <img
            width="20px"
            height="20px"
            src={networkData.icon}
            alt={networkData.name}
            style={{ borderRadius: "50%" }}
          />
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
          <p className="MuiTypography-root MuiTypography-body1 css-qa4mxb">
            {networkData.name}
          </p>
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
          <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
        </div>
        <button
          onClick={handleClick}
          className="h-[40px] h050 laptop:h-[44px] border border-solid border-[#57534E] relative"
        >
          <div className="border-solid gap15 border-[#57534E] flex justify-center">
            <div
              className="flex relative w-[44px] h-[20px] dynamic-width"
              style={{ width: 70 }}
            >
              <img
                className="absolute img"
                src={Eth}
                alt="ETH"
                style={{
                  borderRadius: "50%",
                  left: 0,
                  width: "20px",
                  height: "20px",
                }}
              />
              <img
                className="absolute img"
                src={Bnb}
                alt="BNB"
                style={{
                  borderRadius: "50%",
                  left: 15,
                  width: "20px",
                  height: "20px",
                }}
              />
              <img
                className="absolute img"
                src={Plg}
                alt="PLG"
                style={{
                  borderRadius: "50%",
                  left: 30,
                  width: "20px",
                  height: "20px",
                }}
              />
              <img
                className="absolute img"
                src={Arbi}
                alt="ARB"
                style={{
                  borderRadius: "50%",
                  left: 45,
                  width: "20px",
                  height: "20px",
                }}
              />
              <img
                className="absolute img"
                src={Avax}
                alt="AVAX"
                style={{
                  borderRadius: "50%",
                  left: 60,
                  width: "20px",
                  height: "20px",
                }}
              />
              <img
                className="absolute img"
                src={Opt}
                alt="OP"
                style={{
                  borderRadius: "50%",
                  left: 75,
                  width: "20px",
                  height: "20px",
                }}
              />
              <img
                className="absolute img"
                src={Base}
                alt="BASE"
                style={{
                  borderRadius: "50%",
                  left: 90,
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>

            <div>
              <svg
                width={25}
                height={25}
                viewBox="0 0 25 25"
                fill="#fc6432"
                xmlns="http://www.w3.org/2000/svg"
                className="flex relative h-[20px]"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9625 10.0171L12.0825 13.8971L8.2025 10.0171C7.8125 9.62711 7.1825 9.62711 6.7925 10.0171C6.4025 10.4071 6.4025 11.0371 6.7925 11.4271L11.3825 16.0171C11.7725 16.4071 12.4025 16.4071 12.7925 16.0171L17.3825 11.4271C17.7725 11.0371 17.7725 10.4071 17.3825 10.0171C16.9925 9.63711 16.3525 9.62711 15.9625 10.0171Z"
                  fill="#ffffff"
                />
              </svg>
            </div>
          </div>
        </button>
      </div>
    </>
  );
}
