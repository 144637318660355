// usePurchase.js

import { useDispatch, useSelector } from "react-redux";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { setBlur } from "../reducers/blurSlice";
import {
  setModal1,
  setModal2,
  setModal5,
  setModal6,
} from "../reducers/modalSlice";
import { setSnovaData } from "../reducers/snovaDataSlice";
import { setTotalSnovaTokens } from "../reducers/totalSnovaTokensSlice";
import { setTotalSnovaValue } from "../reducers/totalSnovaValueSlice";
import { setAllTransactions } from "../reducers/transactionsSlice";
import { setTotalNovaPoints } from "../reducers/totalNovaPointsSlice";
import {
  setTotalReferralRewards,
  setPendingReferralRewardsInDollars,
} from "../reducers/totalReferralRewardsSlice";
import { setUserRankingByRewards } from "../reducers/userRankingByRewardsSlice";
import { setUserRankingByNovaPoints } from "../reducers/userRankingByNovaPointsSlice";
import { setNovaPoints } from "../reducers/amountsSlice";
import { setRewards } from "../reducers/referralRewardsSlice";
import { truncateToDecimalPlace } from "../utils/helpers";
import tokensByChainId from "../utils/tokensByChainId";
import { presaleAddressesByChainId } from "../utils/contractAddressesByChainId";
import { Contract, ethers } from "ethers";

const PRESALE_ABI = [
  "event TokensPurchased(address indexed user, address indexed ref, uint256 amount, uint256 price, uint256 sold, uint256 round, uint256 investmentUSD, int256 currencyPrice, uint256 novaPoints)",
  "event NovaPointsAwarded(address indexed user, uint256 points)",
  "function purchaseTokens(address ref_, address tokenAddress_, uint256 amount_) external payable",
];

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const getReferrerAddress = async (referrerUrl) => {
  if (!referrerUrl) {
    return ZERO_ADDRESS;
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getReferrerAddress`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ referrerUrl }),
        credentials: "include",
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data.referrerAddress;
    } else {
      return ZERO_ADDRESS;
    }
  } catch (error) {
    return ZERO_ADDRESS;
  }
};

const getActivePresaleStage = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getActivePresaleStage`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const extractTransactionPayload = (
  tokensPurchasedEvent,
  receipt,
  referrerAddr,
  activePresaleStage,
  currency,
  address,
  chainId,
  referrerUrl,
  referralLink
) => {
  const decimals = tokensByChainId[chainId][currency].decimals;

  const purchaseAmount = ethers.utils.formatUnits(
    tokensPurchasedEvent.args.amount,
    decimals
  );

  const transactionHash = receipt.transactionHash;
  const blockNumber = receipt.blockNumber;
  const gasUsed = receipt.gasUsed.toNumber();
  const gasPrice = receipt.effectiveGasPrice;
  const snovaTokens = ethers.utils.formatUnits(
    tokensPurchasedEvent.args.sold,
    18
  );

  const transactionDate = new Date().toISOString();
  const investmentUSD = ethers.utils.formatUnits(
    tokensPurchasedEvent.args.investmentUSD,
    18
  );
  const currencyPrice = tokensPurchasedEvent.args.currencyPrice.toString();
  const novaPoints = tokensPurchasedEvent.args.novaPoints.toString();
  //!!!!!!SUPER IMPORTANT TO UNCOMMENT!!!!!!!//////
  // if (tokensPurchasedEvent.args.ref.toString() !== referrerAddr) {
  //   return {
  //     error: "Referral address mismatch detected. To be investigated",
  //   };
  // }

  return {
    address,
    purchaseAmount,
    currency_price: currencyPrice,
    purchase_amount_dollar: truncateToDecimalPlace(investmentUSD, 2),
    currency,
    network: chainId,
    transactionHash,
    contractAddress: presaleAddressesByChainId[chainId],
    snovaTokens,
    novaPoints,
    transactionDate,
    referrerUrl,
    referrerAddress: tokensPurchasedEvent.args.ref.toString(),
    presaleStage: activePresaleStage,
    gasUsed,
    gasPrice: gasPrice.toString(),
    blockNumber,
    referralLink,
  };
};

const usePurchase = () => {
  const dispatch = useDispatch();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const referrer = useSelector((state) => state.referrer.referrer);
  const referralLink = useSelector((state) => state.referral.referral);
  const initialReferrerAddr = useSelector(
    (state) => state.referrer.referrerAddress
  );

  const purchase = async (amount, currency) => {
    const referrerUrl = referrer;
    let referrerAddr = initialReferrerAddr;

    dispatch(setBlur(true));
    if (!isConnected) throw new Error("User disconnected");
    if (!referrerAddr) {
      referrerAddr = await getReferrerAddress(referrerUrl);
    }
    if (referrerAddr === null) {
      dispatch(
        setModal2({
          isOpen: true,
          data: { error: "Failed to get referrer address" },
        })
      );
      dispatch(setBlur(false));
      return;
    }

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const PresaleContract = new Contract(
      presaleAddressesByChainId[chainId],
      PRESALE_ABI,
      signer
    );
    try {
      const decimals = tokensByChainId[chainId][currency].decimals;
      const amountString = amount.toString();
      const parsedAmount = ethers.utils.parseUnits(amountString, decimals);

      const transactionOptions = ["ETH", "BNB", "POL", "AVAX"].includes(
        currency
      )
        ? { value: parsedAmount }
        : { value: 0 };

      const passedAmountBasedOnCurrency = [
        "ETH",
        "BNB",
        "POL",
        "AVAX",
      ].includes(currency)
        ? 0
        : parsedAmount;

      const tokenAddresses = tokensByChainId[chainId] || {};
      const tokenAddress = tokenAddresses[currency]?.address;

      if (!tokenAddress) {
        dispatch(
          setModal2({
            isOpen: true,
            data: {
              error: `Token address for ${currency} not found on chain ${chainId}`,
            },
          })
        );
        dispatch(setBlur(false));
        return;
      }

      const transactionResponse = await PresaleContract.purchaseTokens(
        referrerAddr,
        tokenAddress,
        passedAmountBasedOnCurrency,
        {
          ...transactionOptions,
          gasLimit: ethers.utils.hexlify(1000000),
        }
      );

      const receipt = await transactionResponse.wait();

      if (receipt.status === 1) {
        const tokensPurchasedEvent = receipt.events.find(
          (event) => event.event === "TokensPurchased"
        );

        if (!tokensPurchasedEvent) {
          dispatch(
            setModal2({
              isOpen: true,
              data: { error: "TokensPurchased event not found in receipt." },
            })
          );
          dispatch(setBlur(false));
          return;
        }

        const activePresaleStage = await getActivePresaleStage();
        if (!activePresaleStage) {
          dispatch(
            setModal2({
              isOpen: true,
              data: { error: "Failed to get active presale stage" },
            })
          );
          dispatch(setBlur(false));
          return;
        }

        const transactionPayload = extractTransactionPayload(
          tokensPurchasedEvent,
          receipt,
          referrerAddr,
          activePresaleStage,
          currency,
          address,
          chainId,
          referrerUrl,
          referralLink
        );

        if (transactionPayload.error) {
          dispatch(
            setModal2({
              isOpen: true,
              data: { error: transactionPayload.error },
            })
          );
          dispatch(setBlur(false));
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/transactionHandler`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(transactionPayload),
            credentials: "include",
          }
        );

        if (response.ok) {
          dispatch(setModal1({ isOpen: true, data: transactionPayload }));

          // After successful transaction processing, fetch the updated data
          try {
            const snovaDataResponse = await fetch(
              `${process.env.REACT_APP_SERVER_URL}/getSnovaData`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
              }
            );

            if (snovaDataResponse.ok) {
              const snovaData = await snovaDataResponse.json();
              dispatch(setSnovaData(snovaData));

              // Fetch Investor Dashboard Data
              await fetchInvestorDashboardData();
            } else {
              console.error("Failed to fetch updated Snova data.");
            }
          } catch (error) {
            console.error("Error fetching updated Snova data:", error);
          }
        } else {
          console.error("Failed to process transaction.");
        }
      }
    } catch (error) {
      // Handle wallet rejections and errors
      if (
        error.code === 4001 ||
        (error.code === undefined && error.message.includes("User rejected")) ||
        error.message.includes("rejected") ||
        error.message.includes("declined") ||
        error.message.includes("cancelled")
      ) {
        dispatch(
          setModal5({
            isOpen: true,
            data: { error: "Transaction was declined by the user." },
          })
        );
        console.log("Transaction was rejected by the user.");
      } else if (
        (error.code === -32000 &&
          (error.message.includes("intrinsic gas too low") ||
            error.message.includes("gas required exceeds allowance") ||
            error.message.includes("out of gas") ||
            error.message.includes("exceeds gas limit"))) ||
        error.message.includes("gas too low")
      ) {
        dispatch(setModal2({ isOpen: true, data: { error: error.message } }));
        console.log("Gas too low");
      } else {
        dispatch(setModal6({ isOpen: true, data: { error: error.message } }));
      }
    } finally {
      dispatch(setBlur(false));
    }
  };

  // Function to fetch Investor Dashboard Data
  const fetchInvestorDashboardData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getInvestorDashboard`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ walletAddress: address }),
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Dispatch data to Redux store
        dispatch(setTotalSnovaTokens(data.totalSnovaTokens || 0));
        dispatch(setRewards({ SNOVA: data.earnedSnovaTokens || 0 }));
        dispatch(setTotalSnovaValue(data.totalSnovaValue || 0));
        dispatch(setAllTransactions(data.transactions || []));
        dispatch(setTotalNovaPoints(data.totalNovaPoints || 0));
        dispatch(setNovaPoints(data.earnedNovaPoints || 0));
        dispatch(
          setTotalReferralRewards(data.totalReferralRewardsInDollars || 0)
        );
        dispatch(
          setPendingReferralRewardsInDollars(
            data.pendingReferralRewardsInDollars || 0
          )
        );
        dispatch(setUserRankingByRewards(data.userRankingByRewards || null));
        dispatch(
          setUserRankingByNovaPoints(data.userRankingByNovaPoints || null)
        );
      } else {
        let errorData = null;
        try {
          errorData = await response.json();
        } catch (jsonError) {
          console.error("Error parsing error response JSON:", jsonError);
        }
        const errorMessage =
          errorData && errorData.error ? errorData.error : "Unknown error";
        console.error("Error fetching investor dashboard data:", errorMessage);
      }
    } catch (error) {
      console.error("Error fetching investor dashboard data:", error);
    }
  };

  return { purchase };
};

export default usePurchase;
