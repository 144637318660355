// CurrencyOption.js

import React from "react";
import { currencyIcons } from "../../../../utils/currenciesUtils";
import { formatValue } from "../../../../utils/helpers";

const CurrencyOption = ({
  currency,
  currencyName,
  balance,
  balanceInDollar,
  isConnected,
}) => (
  <div className="w-full grid grid-cols-fr items-center">
    <div className="css-70qvj9">
      <div className="css-1o3uuyz" style={{ margin: "0px 8px 0px 0px" }}>
        {typeof currencyIcons[currency] === "function" ? (
          React.createElement(currencyIcons[currency], {
            style: { width: "20px", height: "20px" },
            "aria-label": currency,
          })
        ) : (
          <img
            src={currencyIcons[currency]}
            alt={currency}
            style={{ width: "20px", height: "20px", objectFit: "cover" }}
          />
        )}
      </div>
      <div
        className="css-cgq59l"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <p className="text-[#F5F5F4] text-[14px] font-weight-bold">
          {currencyName}
        </p>
      </div>
    </div>
    {isConnected && (
      <>
        <div className="css-70qvj9 justify-center" style={{ width: "100%" }}>
          <div className="css-cgq59l" style={{ width: "47%" }}>
            <p
              className="text-[#F5F5F4] text-[14px] font-weight-bold"
              style={{ textAlign: "right" }}
            >
              {balance !== undefined ? formatValue(balance, 4) : "0.0000"}
            </p>
          </div>
        </div>
        <div className="css-70qvj9 justify-ends">
          <div className="css-cgq59l">
            <p className="text-[#F5F5F4] text-[14px] font-weight-bold">
              $
              {balanceInDollar !== undefined
                ? formatValue(balanceInDollar, 2)
                : "0.00"}
            </p>
          </div>
        </div>
      </>
    )}
  </div>
);

export default CurrencyOption;
