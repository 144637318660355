// import { Tooltip } from "react-tooltip";

import CopyIco from "../../../assets/img/copy.svg";
import CircleInfo from "../../../assets/img/circle-info.svg";

import copy from "../../../assets/img/copy.svg";
import done from "../../../assets/img/done.svg";
import Bsc from "../../../assets/img/bsc-icon.svg";
import Bnb from "../../../assets/img/bnb-icon.png";
import wallet from "../../../assets/img/connect-wallet.svg";
import snovaCoin from "../../../assets/img/snova-coin-white.svg";
import Info from "../../../assets/img/info-icon.svg";
import UserIcon from "../../../assets/img/circle-user.svg";
import novaPoints from "../../../assets/img/nova-points.svg";
import "./Banner.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ConnectButton from "../../Home/Banner/bannerSelectors/ConnectButton";
import { useSelector } from "react-redux";

import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import useFetchInvestorDashboard from "../../../hooks/useFetchInvestorDashboard";
import networkMapping from "../../../utils/networkMapping";
import { currencyIcons } from "../../../utils/currenciesUtils";
import { formatRanking } from "../../../utils/helpers";

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t, i18n } = useTranslation();

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={showTooltip ? "true" : "false"}
        aria-label={text}
      >
        {children}
      </div>
      {showTooltip && (
        <div className="main-tp ">
          <div />
          {text}
        </div>
      )}
    </div>
  );
};
const Banner = () => {
  const { t, i18n } = useTranslation();
  const { address, isConnected } = useWeb3ModalAccount();
  const [dropInner, setdropInner] = useState(false);
  const [modal2, setmodal2] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const { fetchInvestorDashboard } = useFetchInvestorDashboard();

  const totalSnovaTokens = useSelector(
    (state) => state.totalSnovaTokens.totalSnovaTokens
  );
  const totalNovaPoints = useSelector(
    (state) => state.totalNovaPoints.totalNovaPoints
  );
  const totalSnovaValue = useSelector(
    (state) => state.totalSnovaValue.totalSnovaValue
  );
  const referralLink = useSelector((state) => state.referral.referral);
  const transactions = useSelector((state) => state.transactions.transactions);
  const totalReferralRewardsInDollars = useSelector(
    (state) => state.totalReferralRewards.totalReferralRewardsInDollars
  );
  const userRankingByRewards = useSelector(
    (state) => state.userRankingByRewards.ranking
  );
  const userRankingByNovaPoints = useSelector(
    (state) => state.userRankingByNovaPoints.ranking
  );
  console.log({
    userRankingByRewards,
    userRankingByNovaPoints,
    totalReferralRewardsInDollars,
    totalNovaPoints,
    referralLink,
  });
  useEffect(() => {
    fetchInvestorDashboard();
  }, []);

  let toggleInner = () => {
    setdropInner(!dropInner);
  };
  let toggleModal2 = () => {
    setmodal2(!modal2);
  };

  const handleCopyLink = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedLink(true);
        setTimeout(() => setCopiedLink(false), 2000);
      })
      .catch((error) => console.error("Error copying text: ", error));
  };
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((error) => console.error("Error copying text: ", error));
  };

  const isLoading =
    userRankingByRewards === null ||
    userRankingByNovaPoints === null ||
    totalReferralRewardsInDollars === null ||
    totalNovaPoints === null ||
    referralLink === null;

  return (
    <>
      {dropInner && <div onClick={toggleInner} className="in-overlay"></div>}
      {modal2 && <div onClick={toggleModal2} className="in-overlay"></div>}
      <div className="relative  overflow-hidden dab-k  pt-5  banner-sd bg-black">
        {/*  */}
        <div
          className="opacity-0 h-baner animate-[hero-enter_2s_ease-out_0.5s_forwards] absolute top-[216px] ai-dt:top-[184px] globe w-[1459px] h-[1461px]"
          style={{ left: "50%", transform: "translate(-50%, 0%)" }}
        >
          <div
            className="w-[100%] banner-globe height-ati h-[100%] relative"
            style={{
              transform: "rotate(180deg)",
              zIndex: 4,
              flexShrink: 0,
              borderRadius: "100%",
              opacity: "0.4",
              mixBlendMode: "luminosity",
              rotate: "180deg",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={1459}
              viewBox="0 0 1459 1461"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M0.499936 730.5C0.499901 1133.67 326.885 1460.5 729.5 1460.5C1132.11 1460.5 1458.5 1133.67 1458.5 730.5C1458.5 327.331 1132.11 0.499971 729.5 0.499936C326.885 0.499901 0.499971 327.331 0.499936 730.5Z"
                stroke="transparent"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3113_1758g6"
                  x1="729.5"
                  y1="-6.37749e-05"
                  x2="729.5"
                  y2={1461}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" />
                  <stop offset={1} stopColor="white" stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="main-bane max-width">
          <div className="styles_hero_wrapper__YKVi1 ">
            <div className="styles_heading__G8N8m dgra text-banner">
              <div
                className="_initial_401a9_1 _show_401a9_24 mb-676"
                style={{ transitionDelay: "0.1s" }}
              >
                <div className=" ">
                  <div className="pt-[ text-left laptop:pt-[52px]">
                    <Link
                      className="_btn_dcpj6_1 mb-10 inline-flex items-center self-start gap-[10px] p-[8px]"
                      to="/"
                      style={{ background: "rgba(17, 15, 22, 0.64)" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={12}
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          d="M13 6L1.51741 6M5.93379 11L1.2587 6.58925C0.913764 6.26382 0.913764 5.73618 1.2587 5.41074L5.93379 1"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                      <span className="font-[500] text-[white]">
                        {t("referralPage.banner.presaleStartsSoon")}
                      </span>
                    </Link>
                  </div>
                </div>{" "}
                <div className="page_tableContainer__p8Vj_">
                  <div className="rounded-[30px] bg-baseBg flex flex-col text-left w-full p-[32px] sm:p-[20px]">
                    <div className="mb-7">
                      <p className="headingOne text-white font-semibold">
                        {t("dashboardPage.banner.heading1")}
                      </p>
                    </div>
                    {!isConnected ? (
                      <div className="grid grid-cols-1 gap-6 mb-7 items-center justify-center">
                        <ConnectButton
                          type="button"
                          className="ant-btn flex jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                          style={{
                            width: "100%",
                            backgroundColor: "#fc6432",
                            color: "rgb(255, 255, 255)",
                            boxShadow: "6px 6px #C04000",
                          }}
                          buttonText={t("Header.connectWallet")}
                        >
                          <svg
                            width={20}
                            className="wallet-icon no-tp"
                            viewBox="0 0 16 15"
                            fill="white"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
                            />
                          </svg>
                          <span className="btn-buy-token"></span>
                        </ConnectButton>{" "}
                      </div>
                    ) : (
                      <div className="grid grid-cols-2 gap-6 mb-7 md:grid-cols-1 items-stretch">
                        {totalSnovaTokens === null ||
                        totalSnovaValue === null ? (
                          <div className="p-[20px] bg-black rounded-[20px] w-full">
                            <div className="flex items-center text-[20px] font-semibold text-white">
                              <div className="flex mr-1 items-center">
                                <div className="shimmer shimmer-icon" />
                              </div>
                              <span
                                className="shimmer shimmer-text"
                                style={{
                                  width: "135px",
                                  height: "20px",
                                  borderRadius: "4px",
                                }}
                              />
                            </div>
                            <div className="w-full h-[1px] bg-[#1A1B23] my-[13px]" />
                            <div className="grid grid-cols-2 gap-4 md:grid-cols-1">
                              <div className="mr-auto">
                                <span
                                  className="shimmer shimmer-title"
                                  style={{
                                    width: "180px",
                                    height: "20px",
                                    borderRadius: "4px",
                                  }}
                                />
                                <div className="flex gap-2 items-center mt-[8px]">
                                  <span
                                    className="shimmer shimmer-text"
                                    style={{
                                      width: "100px",
                                      height: "26px",
                                      borderRadius: "4px",
                                    }}
                                  />
                                  <span
                                    className="shimmer shimmer-text"
                                    style={{
                                      width: "100px",
                                      height: "16px",
                                      borderRadius: "4px",
                                      marginTop: "2px",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="relative hkk naka group flex items-center">
                                <span
                                  className="shimmer shimmer-text"
                                  style={{
                                    width: "120px",
                                    height: "45px",
                                    borderRadius: "6px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="p-[20px] bg-black rounded-[20px] w-full">
                            <div className="flex items-center text-[20px] font-semibold text-white">
                              <div className="flex mr-1 items-center">
                                <img
                                  src={UserIcon}
                                  style={{ height: "20px" }}
                                />
                              </div>
                              <span className="text-white text-[20px] leading-[100%]">
                                {`${address.slice(0, 6)}...${address.slice(
                                  -4
                                )}`}
                              </span>
                              <div className="relative group flex items-center justify-center">
                                <div
                                  className="transition-all z-20 text-accent text-[16px] min-w-[32px] min-h-[32px] bg-[#424242] px-[8px] py-[6px] rounded-[6px] absolute left-0 
                              mt-0 scale-0 group-hover:scale-100
                              undefined  whitespace-nowrap left-[20px] top-[10px]"
                                >
                                  {" "}
                                  {t("dashboardPage.banner.clickToCopy")}
                                </div>
                                <button
                                  className="hover:brightness-[80%] active:brightness-[60%] inline-flex items-center justify-center"
                                  style={{ marginLeft: 10 }}
                                >
                                  {copied ? (
                                    <div className="_copyBtn_1fjrj_20">
                                      <img src={done} alt="Copy" />
                                    </div>
                                  ) : (
                                    <div
                                      className="_copyBtn_1fjrj_20"
                                      onClick={() => handleCopy(address)}
                                    >
                                      <img src={CopyIco} alt="Copy" />
                                    </div>
                                  )}
                                </button>
                              </div>
                            </div>
                            <div className="w-full h-[1px] bg-[#1A1B23] my-[13px]" />
                            <div className="grid grid-cols-2 gap-4 md:grid-cols-1">
                              <div className="mr-auto">
                                <p className="text-[20px] font-semibold text-[#918FA5]">
                                  {t("dashboardPage.banner.snovaTokens")}
                                </p>
                                <div className="flex items-center gap-2">
                                  <span className="text-accent font-bold text-[26px]">
                                    {totalSnovaTokens
                                      ? totalSnovaTokens.toFixed(2)
                                      : 0}
                                  </span>
                                  <span
                                    className="snova-dollar"
                                    style={{
                                      fontWeight: "400",
                                      color: "#918FA5",
                                      fontSize: "12px",
                                      lineHeight: "normal",
                                    }}
                                  >
                                    /
                                  </span>
                                  <span className="font-medium text-[16px] snova-dollar">
                                    {totalSnovaValue
                                      ? `$${totalSnovaValue.toFixed(2)}`
                                      : "$0"}
                                  </span>
                                </div>
                              </div>
                              <div className="relative hkk naka group flex items-center">
                                <div
                                  className="transition-all z-20 text-accent text-[16px] min-w-[32px] min-h-[32px] bg-[#424242] px-[8px] py-[6px] rounded-[6px] absolute left-0 
      mt-0 scale-0 group-hover:scale-100 left-[100%] min-w-max translate-y-[-50%] !bg-[#242424] !rounded-[20px] !p-[20px] md:left-auto md:right-0 md:translate-y-[-100%] sm:left-0"
                                >
                                  <div className="flex flex-col items-center">
                                    <span className="text-white mb-2 text-[18px]">
                                      {t("dashboardPage.banner.claimHeading")}
                                    </span>
                                    <a
                                      href="https://docs.stacknova.ai/stacknova-ai/getting-started/presale"
                                      target="_blank"
                                      className="text-[#8D8D8D] hover:text-accent text-[16px] font-semibold"
                                    >
                                      {t("dashboardPage.banner.learnMore")}
                                    </a>
                                  </div>
                                </div>
                                <div className="no-dis">
                                  <Tooltip
                                    text={t("dashboardPage.banner.toolTip")}
                                  >
                                    <button
                                      className="manh px-[16px] hkk py-[11px] wsop bg-defaultOrange _base_rhd5v_1 _orange_rhd5v_17 font-DM_Mono font-medium flex justify-center items-center text-typographyBlack whitespace-break-spaces text-[16px] laptop:text-[20px] mt-[20px]"
                                      disabled
                                      style={{ cursor: "not-allowed" }}
                                    >
                                      <span className="mx-[12px] hkk text-[16px] laptop:text-[20px] text-[#110F16] _text_rhd5v_20">
                                        <span className="text-[18px] flex items-center leading-[160%]">
                                          <span className="claim-text">
                                            {t("dashboardPage.banner.claimBtn")}
                                          </span>
                                          <img
                                            src={Info}
                                            style={{
                                              height: "20px",
                                              marginLeft: "7.5px",
                                            }}
                                          />
                                        </span>
                                      </span>
                                    </button>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isLoading ? (
                          <div
                            className="p-[20px] bg-black rounded-[20px] w-full"
                            style={{
                              width: "100%",
                            }}
                          >
                            <div className="grid grid-cols-2 md:grid-cols-1 gap-4 items-center">
                              <div
                                className="flex mr-auto items-center"
                                style={{
                                  width: "160px",
                                }}
                              >
                                <span
                                  className="shimmer shimmer-text"
                                  style={{
                                    width: "160px",
                                    height: "20px",
                                    borderRadius: "4px",
                                  }}
                                />
                              </div>
                              <span
                                className="shimmer shimmer-text"
                                style={{
                                  width: "150px",
                                  height: "16px",
                                  borderRadius: "4px",
                                  margin: "5px",
                                }}
                              />
                            </div>
                            <div className="w-full h-[1px] bg-[#1A1B23] my-[13px]" />
                            <div className="grid grid-cols-2 md:grid-cols-1 gap-4">
                              <div className="mr-auto">
                                <span
                                  className="shimmer shimmer-title"
                                  style={{
                                    width: "170px",
                                    height: "20px",
                                    borderRadius: "4px",
                                  }}
                                />
                                <div className="flex items-center gap-2 mt-[8px]">
                                  <span
                                    className="shimmer shimmer-text"
                                    style={{
                                      width: "100px",
                                      height: "26px",
                                      borderRadius: "4px",
                                    }}
                                  />
                                  <span
                                    className="shimmer shimmer-text"
                                    style={{
                                      width: "60px",
                                      height: "16px",
                                      borderRadius: "4px",
                                      marginTop: "2px",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="mr-auto">
                                <span
                                  className="shimmer shimmer-title"
                                  style={{
                                    width: "180px",
                                    height: "20px",
                                    borderRadius: "4px",
                                  }}
                                />
                                <div className="text-[26px] text-accent mt-[8px]">
                                  <div
                                    className="shimmer shimmer-text"
                                    style={{
                                      width: "100px",
                                      height: "26px",
                                      borderRadius: "4px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="p-[20px] bg-black rounded-[20px] w-full">
                            <div className="grid grid-cols-2 md:grid-cols-1 gap-4 items-center">
                              <div className="flex mr-auto items-center">
                                {/* {isConnected && referralLink ? (
                                <span className="text-[20px] leading-[20px] font-medium text-[#8D8D8D]">
                                  {displayLink}{" "}
                                </span>
                              ) : ( */}
                                <span className="text-[20px] leading-[20px] font-medium text-[#8D8D8D]">
                                  {t("dashboardPage.banner.referralRank")}:{" "}
                                  {userRankingByRewards
                                    ? formatRanking(userRankingByRewards)
                                    : "#"}
                                </span>
                                {/* )} */}
                                <div className="relative group flex items-center justify-center">
                                  <div
                                    className="transition-all z-20 text-accent text-[16px] min-w-[32px] min-h-[32px] bg-[#424242] px-[8px] py-[6px] rounded-[6px] absolute left-0 
                              mt-0 scale-0 group-hover:scale-100
                            undefined  whitespace-nowrap left-[20px] top-[10px]"
                                  >
                                    {t("dashboardPage.banner.clickToCopy")}
                                  </div>
                                  {/* <button
                                  className="hover:brightness-[80%] active:brightness-[60%] inline-flex items-center justify-center"
                                  style={{ marginLeft: 10 }}
                                >
                                  {copied2 ? (
                                    <div className="_copyBtn_1fjrj_20">
                                      <img src={done} alt="Copy" />
                                    </div>
                                  ) : (
                                    <div
                                      className="_copyBtn_1fjrj_20"
                                      onClick={() => handleCopy2(referralLink)}
                                    >
                                      <img src={CopyIco} alt="Copy" />
                                    </div>
                                  )}
                                </button> */}
                                </div>
                              </div>
                              <span className="text-[#8D8D8D] text-[16px]">
                                {t("dashboardPage.banner.scoreRank")}:{" "}
                                {userRankingByNovaPoints
                                  ? formatRanking(userRankingByNovaPoints)
                                  : "#"}
                              </span>
                            </div>
                            <div className="w-full h-[1px] bg-[#1A1B23] my-[13px]" />
                            <div className="grid grid-cols-2 md:grid-cols-1 gap-4">
                              <div className="mr-auto">
                                <span className="text-[20px] font-semibold text-[#918FA5]">
                                  {t("dashboardPage.banner.referralEarnings")}
                                </span>
                                <div className="text-[26px]   referral-earnings  rt-Flex rt-r-display-flex rt-r-jc-start rt-r-gap-1">
                                  {totalReferralRewardsInDollars
                                    ? "$" +
                                      totalReferralRewardsInDollars.toFixed(2)
                                    : "$0"}{" "}
                                  <span
                                    className="rt-Text sc-qZrbh flex items-center iHUxZB"
                                    style={{ fontSize: "12px" }}
                                  >
                                    / {t("dashboardPage.banner.myReferralLink")}{" "}
                                    {copiedLink ? (
                                      <div className="_copyBtn_1fjrj_20 maer ">
                                        <img
                                          src={done}
                                          className="don-ing"
                                          alt="Done"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="_copyBtn_1fjrj_20 maer"
                                        onClick={() =>
                                          handleCopyLink(referralLink)
                                        }
                                      >
                                        <img src={copy} alt="Copy" />
                                      </div>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="mr-auto">
                                <div className="text-[20px] for-sb flex items-center font-semibold text-[#918FA5]">
                                  {t("dashboardPage.banner.novaPoints")}{" "}
                                  <a
                                    href="https://docs.stacknova.ai/stacknova-ai/getting-started/presale/nova-points"
                                    target="_blank"
                                  >
                                    <Tooltip text={t("toolTips.learnMore")}>
                                      {" "}
                                      <img
                                        src={CircleInfo}
                                        style={{
                                          height: "20px",
                                          marginLeft: "7.5px",
                                        }}
                                      />
                                    </Tooltip>
                                  </a>
                                </div>
                                <div className="text-[26px] text-accent">
                                  <img
                                    src={novaPoints}
                                    className="nova-points"
                                  />
                                  {totalNovaPoints
                                    ? totalNovaPoints.toFixed(2)
                                    : 0}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/*DISPLAY ONLY WHEN CONNECTED*/}
                      </div>
                    )}{" "}
                    {isConnected && (
                      <div>
                        {" "}
                        <div className="mb-7">
                          <p className=" headingTwo text-white font-semibold ">
                            {t("dashboardPage.banner.purchaseHistory")}
                          </p>
                        </div>
                        <div className="p-0 bg-black rounded-[20px] w-full min-h-[370px] min-h relative custom-scroll-container">
                          <div className="scroll-inner">
                            <table className="w-full text-left table-fixed border-collapse">
                              <tbody>
                                {/* <tr className="text-white font-normal text-[24px] md:text-[14px] border-boa border-boa  ">
                                <td className="align-bottom   py-[10px] p-20p border-b border-boa">
                                  <div className="flex flex-col">
                                    <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                      28 Apr 2024
                                    </div>

                                    <div className="flex items-center gap-1 smam">
                                      <img
                                        alt="chain icon"
                                        loading="lazy"
                                        width={40}
                                        height={40}
                                        decoding="async"
                                        data-nimg={1}
                                        className="w-[24px] h-[24px] sddaa md:w-[14px] md:h-[14px] mb-1 logovl"
                                        src={Bsc}
                                        style={{ color: "transparent" }}
                                      />
                                      BSC
                                    </div>
                                  </div>
                                </td>
                                <td className="align-bottom   py-[10px] p-20p border-b border-boa">
                                  <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                    You Bought
                                  </div>

                                  <div className="flex items-center gap-1">
                                    <img
                                      alt="asset icon"
                                      loading="lazy"
                                      width={24}
                                      height={24}
                                      decoding="async"
                                      data-nimg={1}
                                      className=" mb-1 smallera"
                                      src={Bnb}
                                      style={{ color: "transparent" }}
                                    />
                                    <span
                                      style={{ fontWeight: "600" }}
                                      className="inner-ft"
                                    >
                                      0.003
                                    </span>
                                    <span className="text-[14px] leading-[31px] self-end md:text-[10px] md:leading-[19px] font-bold">
                                      BNB
                                    </span>
                                  </div>
                                </td>
                                <td className="align-bottom   py-[10px] p-20p border-b border-boa">
                                  <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                    You Received
                                  </div>

                                  <div className="flex items-center gap-1">
                                    <img
                                      alt="asset icon"
                                      loading="lazy"
                                      width={24}
                                      height={24}
                                      decoding="async"
                                      data-nimg={1}
                                      className=" mb-1 smallera"
                                      src={snovaCoin}
                                      style={{ color: "transparent" }}
                                    />
                                    <span
                                      style={{ fontWeight: "600" }}
                                      className="inner-ft"
                                    >
                                      152032
                                    </span>
                                    <span className="text-[14px] leading-[31px] self-end md:text-[10px] md:leading-[19px] font-bold">
                                      SNOVA
                                    </span>
                                  </div>
                                </td>
                                <td className="align-bottom py-[10px] p0 0 px-[20px] border-b border-boa">
                                  <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                    View More
                                  </div>
                                  <div className="flex gap-2 md:gap-1 items-center main-hk">
                                    <a
                                      href="https://bscscan.com/tx/0xfe746532c9536cb91d13f6616101fda6ef9bd965a53a58a6720c2c08096d9b23"
                                      target="_blank"
                                      className="font-normal text-[20px] md:text-[10px] hover:brightness-75 active:brightness-75 details-text"
                                    >
                                      {" "}
                                      Transaction Details{" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        className="mb-1 md:w-[18px] details-icon"
                                      >
                                        <path
                                          d="M14 4H20M20 4V10M20 4L12 12"
                                          stroke="#fc6432"
                                          strokeWidth={2}
                                        />
                                        <path
                                          d="M11 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V13"
                                          stroke="#fc6432"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                              <tr className="text-white font-normal text-[24px] md:text-[14px] border-boa border-boa  ">
                                <td className="align-bottom   py-[10px] p-20p border-b border-boa">
                                  <div className="flex flex-col">
                                    <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                      28 Apr 2024
                                    </div>
                                    <div className="flex items-center gap-1 smam">
                                      <img
                                        alt="chain icon"
                                        loading="lazy"
                                        width={40}
                                        height={40}
                                        decoding="async"
                                        data-nimg={1}
                                        className="w-[24px] h-[24px] sddaa md:w-[14px] md:h-[14px] mb-1 logovl"
                                        src={Bsc}
                                        style={{ color: "transparent" }}
                                      />
                                      BSC
                                    </div>
                                  </div>
                                </td>
                                <td className="align-bottom   py-[10px] p-20p border-b border-boa">
                                  <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                    You Bought
                                  </div>
                                  <div className="flex items-center gap-1">
                                    <img
                                      alt="asset icon"
                                      loading="lazy"
                                      width={24}
                                      height={24}
                                      decoding="async"
                                      data-nimg={1}
                                      className=" mb-1 smallera"
                                      src={Bnb}
                                      style={{ color: "transparent" }}
                                    />
                                    <span
                                      style={{ fontWeight: "600" }}
                                      className="inner-ft"
                                    >
                                      0.003
                                    </span>
                                    <span className="text-[14px] leading-[31px] self-end md:text-[10px] md:leading-[19px] font-bold">
                                      BNB
                                    </span>
                                  </div>
                                </td>
                                <td className="align-bottom   py-[10px] p-20p border-b border-boa">
                                  <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                    You Received
                                  </div>
                                  <div className="flex items-center gap-1">
                                    <img
                                      alt="asset icon"
                                      loading="lazy"
                                      width={24}
                                      height={24}
                                      decoding="async"
                                      data-nimg={1}
                                      className=" mb-1 smallera"
                                      src={snovaCoin}
                                      style={{ color: "transparent" }}
                                    />
                                    <span
                                      style={{ fontWeight: "600" }}
                                      className="inner-ft"
                                    >
                                      5232.232
                                    </span>
                                    <span className="text-[14px] leading-[31px] self-end md:text-[10px] md:leading-[19px] font-bold">
                                      SNOVA
                                    </span>
                                  </div>
                                </td>
                                <td className="align-bottom py-[10px]   px-[20px] border-b border-boa">
                                  <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                    View More
                                  </div>
                                  <div className="flex gap-2 md:gap-1 items-center main-hk">
                                    <a
                                      href="https://bscscan.com/tx/0xfe746532c9536cb91d13f6616101fda6ef9bd965a53a58a6720c2c08096d9b23"
                                      target="_blank"
                                      className="font-normal text-[20px] md:text-[10px] hover:brightness-75 active:brightness-75 details-text"
                                    >
                                      {" "}
                                      Transaction Details{" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        className="mb-1 md:w-[18px] details-icon"
                                      >
                                        <path
                                          d="M14 4H20M20 4V10M20 4L12 12"
                                          stroke="#fc6432"
                                          strokeWidth={2}
                                        />
                                        <path
                                          d="M11 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V13"
                                          stroke="#fc6432"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                    </a>
                                  </div>
                                </td>
                              </tr>{" "} */}
                                {transactions === null ? (
                                  <tbody>
                                    {Array.from({ length: 5 }).map(
                                      (_, index) => (
                                        <tr
                                          key={index}
                                          className="text-white font-normal text-[24px] md:text-[14px] border-boa border-boa"
                                        >
                                          <td
                                            className="align-bottom py-[10px] p-[20px] border-b border-boa"
                                            style={{
                                              width: "600px",
                                            }}
                                          >
                                            <div className="flex flex-col">
                                              <div
                                                className="shimmer shimmer-text"
                                                style={{
                                                  width: "45%",
                                                  height: "14px",
                                                  marginBottom: "8px",
                                                }}
                                              />
                                              <div className="flex items-center gap-1">
                                                <div
                                                  className="shimmer shimmer-icon w-[24px] h-[24px]"
                                                  style={{
                                                    width: "14px",
                                                    height: "14px",
                                                  }}
                                                />
                                                <div
                                                  className="shimmer shimmer-text"
                                                  style={{
                                                    width: "45%",
                                                    height: "14px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </td>

                                          <td
                                            className="align-bottom py-[10px] p-[20px] border-b border-boa"
                                            style={{
                                              width: "21%",
                                            }}
                                          >
                                            <div className="flex flex-col">
                                              <div
                                                className="shimmer shimmer-text"
                                                style={{
                                                  width: "50%",
                                                  height: "14px",
                                                  marginBottom: "8px",
                                                }}
                                              />
                                              <div className="flex items-center gap-1">
                                                <div
                                                  className="shimmer shimmer-icon w-[24px] h-[24px]"
                                                  style={{
                                                    width: "14px",
                                                    height: "14px",
                                                    margin: "1px",
                                                  }}
                                                />
                                                <div
                                                  className="shimmer shimmer-text"
                                                  style={{
                                                    width: "60%",
                                                    height: "14px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </td>

                                          <td
                                            className="align-bottom py-[10px] p-[20px] border-b border-boa"
                                            style={{
                                              width: "21%",
                                            }}
                                          >
                                            <div className="flex flex-col">
                                              <div
                                                className="shimmer shimmer-text"
                                                style={{
                                                  width: "50%",
                                                  height: "14px",
                                                  marginBottom: "8px",
                                                }}
                                              />
                                              <div className="flex items-center gap-1">
                                                <div
                                                  className="shimmer shimmer-icon w-[24px] h-[24px]"
                                                  style={{
                                                    width: "14px",
                                                    height: "14px",
                                                    margin: "1px",
                                                  }}
                                                />
                                                <div
                                                  className="shimmer shimmer-text"
                                                  style={{
                                                    width: "60%",
                                                    height: "14px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </td>

                                          <td
                                            className="align-bottom py-[10px] p-[20px] border-b border-boa"
                                            style={{
                                              width: "30%",
                                            }}
                                          >
                                            {" "}
                                            <div className="flex flex-col">
                                              <div
                                                className="shimmer shimmer-text"
                                                style={{
                                                  width: "30%",
                                                  height: "14px",
                                                  marginBottom: "8px",
                                                }}
                                              />
                                              <div
                                                className="shimmer shimmer-text"
                                                style={{
                                                  width: "85%",
                                                  height: "14px",
                                                }}
                                              />{" "}
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                ) : (
                                  transactions &&
                                  transactions
                                    .slice()
                                    .reverse()
                                    .map((txn) => {
                                      const network =
                                        networkMapping[txn.network];
                                      const currencyIcon =
                                        currencyIcons[txn.currency];

                                      if (!network) {
                                        return null;
                                      }

                                      const formatDate = (dateString) => {
                                        const date = new Date(
                                          dateString
                                            .split("/")
                                            .reverse()
                                            .join("-")
                                        );
                                        return date.toLocaleDateString(
                                          "en-GB",
                                          {
                                            day: "2-digit",
                                            month: "short",
                                            year: "numeric",
                                          }
                                        );
                                      };

                                      return (
                                        <tr
                                          key={txn._id}
                                          className="text-white font-normal text-[24px] md:text-[14px] border-boa border-boa"
                                        >
                                          <td className="align-bottom py-[10px] p-20p border-b border-boa">
                                            <div className="flex flex-col">
                                              <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                                {formatDate(
                                                  txn.transaction_date
                                                )}
                                              </div>
                                              <div className="flex items-center gap-1 smam">
                                                <img
                                                  alt="chain icon"
                                                  loading="lazy"
                                                  width={40}
                                                  height={40}
                                                  decoding="async"
                                                  data-nimg={1}
                                                  className="w-[24px] h-[24px] sddaa md:w-[14px] md:h-[14px] mb-1 logovl"
                                                  src={network.logo}
                                                  style={{
                                                    color: "transparent",
                                                  }}
                                                />
                                                {network.name}
                                              </div>
                                            </div>
                                          </td>
                                          <td className="align-bottom py-[10px] p-20p border-b border-boa">
                                            <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                              {t(
                                                "dashboardPage.purchaseHistoryTranslations.youBought"
                                              )}
                                            </div>
                                            <div className="flex items-center gap-1">
                                              <img
                                                alt="asset icon"
                                                loading="lazy"
                                                width={24}
                                                height={24}
                                                decoding="async"
                                                data-nimg={1}
                                                className="mb-1 smallera"
                                                src={currencyIcon}
                                                style={{ color: "transparent" }}
                                              />
                                              <span
                                                style={{ fontWeight: "600" }}
                                                className="inner-ft"
                                              >
                                                {txn.purchase_amount}
                                              </span>
                                              <span className="text-[14px] leading-[31px] self-end md:text-[10px] md:leading-[19px] font-bold">
                                                {txn.currency}
                                              </span>
                                            </div>
                                          </td>
                                          <td className="align-bottom py-[10px] p-20p border-b border-boa">
                                            <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                              {t(
                                                "dashboardPage.purchaseHistoryTranslations.youReceived"
                                              )}
                                            </div>
                                            <div className="flex items-center gap-1">
                                              <img
                                                alt="asset icon"
                                                loading="lazy"
                                                width={24}
                                                height={24}
                                                decoding="async"
                                                data-nimg={1}
                                                className="mb-1 smallera"
                                                src={snovaCoin}
                                                style={{ color: "transparent" }}
                                              />
                                              <span
                                                style={{ fontWeight: "600" }}
                                                className="inner-ft"
                                              >
                                                {txn.snova_tokens.toFixed(2)}
                                              </span>
                                              <span className="text-[14px] leading-[31px] self-end md:text-[10px] md:leading-[19px] font-bold">
                                                SNOVA
                                              </span>
                                            </div>
                                          </td>
                                          <td className="align-bottom py-[10px] px-[20px] border-b border-boa">
                                            <div className="text-[14px] font-medium text-[#8D8D8D] md:text-[10px]">
                                              {t(
                                                "dashboardPage.purchaseHistoryTranslations.viewMore"
                                              )}
                                            </div>
                                            <div className="flex gap-2 md:gap-1 items-center main-hk">
                                              <a
                                                href={`${network.explorer}${txn.transaction_hash}`}
                                                target="_blank"
                                                className="font-normal text-[20px] md:text-[10px] hover:brightness-75 active:brightness-75 details-text"
                                              >
                                                {t(
                                                  "dashboardPage.purchaseHistoryTranslations.transactionDetails"
                                                )}
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  className="mb-1 ml-2 md:w-[18px] details-icon"
                                                >
                                                  <path
                                                    d="M14 4H20M20 4V10M20 4L12 12"
                                                    stroke="#fc6432"
                                                    strokeWidth={2}
                                                  />
                                                  <path
                                                    d="M11 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V13"
                                                    stroke="#fc6432"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                  />
                                                </svg>
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "22%",
            bottom: "59%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "242s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "22%",
            bottom: "59%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "242s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "135%",
            bottom: "84%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "130s, 6s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "11%",
            bottom: "3%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "341s, 10s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "104%",
            bottom: "30%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "346s, 5s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            roght: "17%",
            bottom: "55%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "302s, 8s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "109%",
            bottom: "9%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "161s, 12s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "23%",
            bottom: "76%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "267s, 13s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "21%",
            bottom: "47%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "180s, 12s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "21%",
            bottom: "47%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "180s, 12s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "2%",
            bottom: "72%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "199s, 12s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "135%",
            bottom: "55%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "213s, 5s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "16%",
            bottom: "51%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "272s, 10s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "74%",
            bottom: "91%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "126s, 6s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "24%",
            bottom: "32%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "136s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "111%",
            bottom: "23%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "213s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "6%",
            bottom: "98%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "225s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "86%",
            bottom: "12%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "211s, 10s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "3%",
            bottom: "40%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "318s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "3%",
            bottom: "40%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "318s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "111%",
            bottom: "60%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "306s, 11s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "20%",
            bottom: "74%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "344s, 7s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "21%",
            bottom: "82%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "234s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "5%",
            bottom: "69%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "210s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "33%",
            bottom: "88%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "141s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "10%",
            bottom: "11%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "279s, 13s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "77%",
            bottom: "14%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "312s, 11s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "5%",
            bottom: "57%",
            width: 30,
            height: 30,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "262s, 5s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "25%",
            bottom: "80%",
            width: 30,
            height: 30,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "284s, 8s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "21%",
            bottom: "53%",
            width: 30,
            height: 30,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "134s, 7s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "37%",
            bottom: "88%",
            width: 30,
            height: 30,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "178s, 12s",
            zIndex: 90,
          }}
        />

        {/*  */}
        <Tooltip id="my-tooltip" />
      </div>
    </>
  );
};

export default Banner;
