import React, { useState } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import chartUser from "../../../assets/img/chart-user.png";
import { Link } from "react-router-dom";

const DashboardButton2 = () => {
  const { isConnected } = useWeb3ModalAccount();

  if (!isConnected) {
    return null;
  }

  return (
    <div className="flex flex-row border-border-landing h-full center-tj items-center justify-center border-l-[1px] pr-1">
      <Link
        to="/dashboard"
        className="connect-lan  bl-inline-flex bl-items-center bl-justify-center bl-transition-colors bl-relative bl-h-[55px] bl-min-w-[260px] bl-text-[26px]/none   bl-text-primary secondary-transform"
      >
        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0" />
        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
        <img src={chartUser} className="choose-language-icon" />
        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
        <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
      </Link>
    </div>
  );
};

export default DashboardButton2;
