import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import openAi from "../../../assets/img/openai-logo.svg";
import bitcoin from "../../../assets/img/bitcoin-logo.svg";
import stacks from "../../../assets/img/full-stacks-logo.png";
import aws from "../../../assets/img/aws-logo.svg";
import LoaderIcon from "../../../assets/img/icon.gif";
import Marquee from "react-fast-marquee";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import metamask from "../../../assets/img/MetaMask.svg";
import trustwallet from "../../../assets/img/trustwallet-logo.png";
import walletconnect from "../../../assets/img/walletconnect-logo.png";
import unisat from "../../../assets/img/unisat.png";
import okx from "../../../assets/img/okx-wallet.svg";
import leather from "../../../assets/img/leather-logo.svg";
import xverse from "../../../assets/img/xverse.png";
import Coin from "../../../assets/img/coin.svg";
import twitterIcon from "../../../assets/img/twitter-icon.svg";
import discordIcon from "../../../assets/img/discord-icon.svg";
import telegramIcon from "../../../assets/img/telegram-icon.svg";
import githubIcon from "../../../assets/img/github-icon.svg";
import barIcon from "../../../assets/img/bar-icon.svg";
import wallet from "../../../assets/img/connect-wallet.svg";
import copy from "../../../assets/img/copy.svg";
import Eth from "../../../assets/img/eth-icon.png";
import Plg from "../../../assets/img/plg-icon.png";
import Avax from "../../../assets/img/avax-icon.png";
import Bnb from "../../../assets/img/bnb-icon.png";
import Arbi from "../../../assets/img/arbitrum-icon.png";
import Opt from "../../../assets/img/optimism-icon.png";
import Base from "../../../assets/img/base-icon.png";
import UsdC from "../../../assets/img/usdc-symbol.webp";
import EthSymbol from "../../../assets/img/eth-symbol.webp";
import Tether from "../../../assets/img/tether-icon.webp";
import stacknovaLogoWhite from "../../../assets/img/stacknova-full-white.png";
import logoIcon from "../../../assets/img/logo-icon-dark.png";
import heading from "../../../assets/img/stacknova-heading-pixel.svg";
import snovaCoin from "../../../assets/img/snova-coin-white.svg";
import snovaCoinTrans from "../../../assets/img/snova-coin-transparent.svg";
import questionHelp from "../../../assets/img/question-help.svg";
import done from "../../../assets/img/done.svg";
import { Link } from "react-router-dom";
import ConnectButton from "./bannerSelectors/ConnectButton";
import SNOVAbalanceContent from "./bannerSelectors/snovaBalance";
import NetworkSelector from "./bannerSelectors/chooseNetwork";
import RatioCalculatorCrypto from "./bannerSelectors/ratioCalculatorCrypto";
import RatioCalculatorCard from "./bannerSelectors/ratioCalculatorCard";
import CurrencySelector from "./bannerSelectors/UseTheseNetworks";
import ProgressBar from "./bannerSelectors/ProgressBar";
// import Components from "./bannerSelectors/buy";
import {
  setBuyWithCard,
  initializeBuyWithCard,
} from "../../../reducers/buyWithCardSlice";
import "./banner.css";

const Banner = () => {
  const isBlurred = useSelector((state) => state.blur.isBlurred);
  const balance = useSelector((state) => state.balance.balance);
  const balanceDollar = useSelector((state) => state.balance.balanceDollar);
  const buyWithCard = useSelector((state) => state.buyWithCard.buyWithCard);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [dropInner, setdropInner] = useState(false);
  const [modal2, setmodal2] = useState(false);
  const [copied, setCopied] = useState(false);

  const ActiveCard = () => {
    dispatch(setBuyWithCard(true));
  };

  const ActiveCrypto = () => {
    dispatch(setBuyWithCard(false));
  };

  let toggleInner = () => {
    setdropInner(!dropInner);
  };
  let toggleModal2 = () => {
    setmodal2(!modal2);
  };
  const handleCopy = (text) => {
    navigator.clipboard

      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => console.error("Error copying text: ", error));
  };

  useEffect(() => {
    dispatch(initializeBuyWithCard());
  }, [dispatch]);

  const sectionClass = i18n.language === "tr" ? "add-more" : "";

  return (
    <>
      {dropInner && <div onClick={toggleInner} className="in-overlay"></div>}
      {modal2 && <div onClick={toggleModal2} className="in-overlay"></div>}
      <div className="relative  pt-5  banner-sd bg-black">
        {/*  */}
        <div
          className="opacity-0 h-baner animate-[hero-enter_2s_ease-out_0.5s_forwards] absolute top-[216px] ai-dt:top-[184px] globe w-[1459px] h-[1461px]"
          style={{ left: "50%", transform: "translate(-50%, 0%)" }}
        >
          <div
            className="w-[100%] banner-globe height-ati h-[100%] relative"
            style={{
              transform: "rotate(180deg)",
              zIndex: 4,
              flexShrink: 0,
              borderRadius: "100%",
              opacity: "0.4",
              mixBlendMode: "luminosity",
              rotate: "180deg",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={1459}
              viewBox="0 0 1459 1461"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M0.499936 730.5C0.499901 1133.67 326.885 1460.5 729.5 1460.5C1132.11 1460.5 1458.5 1133.67 1458.5 730.5C1458.5 327.331 1132.11 0.499971 729.5 0.499936C326.885 0.499901 0.499971 327.331 0.499936 730.5Z"
                stroke="transparent"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3113_17586"
                  x1="729.5"
                  y1="-6.37749e-05"
                  x2="729.5"
                  y2={1461}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" />
                  <stop offset={1} stopColor="white" stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="main-bane max-width">
          <div className="styles_hero_wrapper__YKVi1 ">
            <div className="styles_heading__G8N8m text-banner">
              <div className="_initial_401a9_1 _show_401a9_24 head-txt">
                <h1 className="text-white text-[32px] desktop:text-[56px] font-[500] max-w-[588px] leading-[1.1] _title_1r323_13">
                  <img
                    src={heading}
                    style={{ marginBottom: "15px", width: "400px" }}
                  />
                  {t("homePage.banner.heading")}
                </h1>
              </div>
              <div
                className="_initial_401a9_1 _show_401a9_24 mb-676"
                style={{ transitionDelay: "0.1s" }}
              ></div>

              <div className="flex dgr for-mbl">
                <a
                  target="_blank"
                  href="https://twitter.com/StacknovaBTC"
                  className="styles_social_link__Vhvdy"
                >
                  <img
                    alt="twitter"
                    loading="lazy"
                    width={90}
                    height={51}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent" }}
                    src={twitterIcon}
                  />
                </a>

                <a
                  target="_blank"
                  href="https://t.me/StacknovaBTC"
                  className="styles_social_link__Vhvdy"
                >
                  <img
                    alt="telegram"
                    loading="lazy"
                    width={90}
                    height={51}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent", marginLeft: "20px" }}
                    src={telegramIcon}
                  />
                </a>

                {/*<a
                  target="_blank"
                  href="https://github.com/Stacknova-AI"
                  className="styles_social_link__Vhvdy"
                >
                  <img
                    alt="telegram"
                    loading="lazy"
                    width={90}
                    height={51}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent", "margin-left": "20px" }}
                    src={githubIcon}
                  />
                </a>*/}
              </div>
            </div>
            <div id="presaleForm" className="styles_form__bJr2K ">
              <div>
                {" "}
                <div
                  data-is-root-theme="true"
                  data-accent-color="indigo"
                  data-gray-color="slate"
                  data-has-background="false"
                  data-panel-background="translucent"
                  data-radius="medium"
                  data-scaling="100%"
                  className="radix-themes"
                >
                  <div className="sc-jiaSqj bjKEmb ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-align-stretch ant-flex-vertical">
                    <div
                      className="ant-space css-dev-only-do-not-override-1q8nizr ant-space-vertical ant-space-gap-row-large ant-space-gap-col-large"
                      style={{ width: "100%" }}
                    >
                      <div className="ant-space-item">
                        <div className="ant-flex bjKEmbw css-dev-only-do-not-override-1q8nizr ant-flex-align-end ant-flex-justify-space-between">
                          <div className=" css-lb1r9s">
                            <div
                              onClick={ActiveCrypto}
                              className={
                                buyWithCard
                                  ? "hover-one css-grbf26 relative auto-overflow"
                                  : "active css-grbf26 relative"
                              }
                            >
                              <div className="css-gmuwbf ">
                                {buyWithCard ? (
                                  ""
                                ) : (
                                  <>
                                    {" "}
                                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                                  </>
                                )}
                                <p className="text-[16px] font-[500] tracking-[-0.8px] laptop:tracking-normal">
                                  {t("homePage.banner.buyWithCrypto")}
                                </p>
                              </div>
                            </div>
                            <div
                              onClick={ActiveCard}
                              className={
                                buyWithCard
                                  ? "active css-grbf26 relative"
                                  : "hover-one css-grbf26 relative"
                              }
                            >
                              <div className="css-gmuwbf">
                                {buyWithCard ? (
                                  <>
                                    {" "}
                                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-t bl-top-0 bl-right-0"></div>
                                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-b bl-bottom-0 bots bl-left-0" />
                                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-l bl-border-t bl-top-0 bl-left-0"></div>
                                    <div className="bl-border-primary bl-absolute bl-h-2.5 bl-w-2.5 bl-border-r bl-border-b bl-right-0 bl-bottom-0"></div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <p className="text-[16px] font-[500] tracking-[-0.8px] laptop:tracking-normal">
                                  {t("homePage.banner.buyWithCard")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="less-pading">
                        <div className="ant-space-item">
                          <div
                            className="ant-space css-dev-only-do-not-override-1q8nizr ant-space-vertical ant-space-gap-row-large ant-space-gap-col-large"
                            style={{ width: "100%" }}
                          >
                            <div className="ant-space-item">
                              <div className="ant-card css-dev-only-do-not-override-1q8nizr">
                                <ProgressBar />
                              </div>
                              <div className="ant-space-item">
                                <SNOVAbalanceContent
                                  copied={copied}
                                  handleCopy={handleCopy}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ant-space-item">
                          <div
                            className="ant-space css-dev-only-do-not-override-1q8nizr ant-space-vertical ant-space-gap-row-large ant-space-gap-col-large"
                            style={{ width: "100%" }}
                          >
                            <div className="ant-space-item">
                              {/* <button onClick={handleBlur}>
                                Blur for 10 seconds
                              </button> */}
                              <div style={{ position: "relative" }}>
                                <div
                                  className={`${
                                    isBlurred
                                      ? "blur-none relative"
                                      : "relative"
                                  }`}
                                >
                                  <div
                                    className={`blur-area ${
                                      isBlurred ? "blur-comp" : ""
                                    }`}
                                  >
                                    {buyWithCard ? "" : <NetworkSelector />}

                                    <div
                                      className="css-11pg2he"
                                      style={{ marginTop: "24px" }}
                                    >
                                      {buyWithCard ? (
                                        <>
                                          <RatioCalculatorCard />

                                          <CurrencySelector />
                                        </>
                                      ) : (
                                        <>
                                          {/* <div className="css-1lekzkb">
                                            <div className="css-1p1m4ay">
                                              <p className="MuiTypography-root MuiTypography-body1 text-[14px] css-6hkpqo">
                                                {t("homePage.banner.balance")}
                                              </p>
                                              <ConnectButton
                                                buttonText={t(
                                                  "homePage.banner.connectYourWallet"
                                                )}
                                                className="MuiTypography-root MuiTypography-body1 css-1enqu04"
                                              />
                                            </div>
                                          </div> */}
                                          {/* <Components /> */}
                                          <RatioCalculatorCrypto />
                                          <CurrencySelector />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {isBlurred && (
                                    <img
                                      src={LoaderIcon}
                                      alt="Overlay"
                                      className="overlay-icon"
                                    />
                                  )}
                                </div>

                                <div
                                  className="ant-flex css-dev-only-do-not-override-1q8nizr ant-flex-align-stretch ant-flex-gap-large ant-flex-vertical"
                                  style={{}}
                                >
                                  <ConnectButton
                                    type="button"
                                    className="ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#fc6432",
                                      color: "rgb(255, 255, 255)",
                                      boxShadow: "6px 6px #C04000",
                                    }}
                                    disabled={isBlurred ? true : false}
                                    buttonText={
                                      isBlurred
                                        ? "Buying... Confirm In Wallet"
                                        : t("homePage.banner.connectWallet")
                                    }
                                    balance={balance}
                                    balanceInDollar={balanceDollar}
                                  >
                                    {/* <img src={wallet} className="wallet-icon" /> */}
                                    {isBlurred ? (
                                      ""
                                    ) : (
                                      <svg
                                        width={20}
                                        className="wallet-icon no-tp"
                                        viewBox="0 0 16 15"
                                        fill="white"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
                                        />
                                      </svg>
                                    )}{" "}
                                    <span className="btn-buy-token"></span>
                                  </ConnectButton>
                                  <a
                                    className="ant-typography css-dev-only-do-not-override-1q8nizr"
                                    href="https://docs.stacknova.ai/stacknova-ai/getting-started/presale/your-guide-to-buying-snova"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "rgba(255, 255, 255, 0.7)",
                                      fontSize: 20,
                                      textAlign: "center",
                                    }}
                                  >
                                    {t("homePage.banner.howToBuy")}
                                  </a>
                                </div>
                                <p className="text-center text-[#79716B] text-[10px] font-[500] mt-[12px] laptop:mt-[20px]">
                                  {t("homePage.banner.termsAndConditions")}
                                </p>
                              </div>
                            </div>

                            <div className="ant-space-item" />
                            <div className="ant-space-item" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`styles_launch_wrapper__RMuag  `}>
                  <div className="flex dgr for-desk">
                    <a
                      target="_blank"
                      href="https://twitter.com/StacknovaBTC"
                      className="styles_social_link__Vhvdy"
                    >
                      <img
                        alt="twitter"
                        loading="lazy"
                        width={90}
                        height={51}
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent" }}
                        src={twitterIcon}
                      />
                    </a>

                    <a
                      target="_blank"
                      href="https://t.me/StacknovaBTC"
                      className="styles_social_link__Vhvdy"
                    >
                      <img
                        alt="telegram"
                        loading="lazy"
                        width={90}
                        height={51}
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent", marginLeft: "20px" }}
                        src={telegramIcon}
                      />
                    </a>

                    {/*
                    <a
                      target="_blank"
                      href="https://github.com/Stacknova-AI"
                      className="styles_social_link__Vhvdy"
                    >
                      <img
                        alt="github"
                        loading="lazy"
                        width={90}
                        height={51}
                        decoding="async"
                        data-nimg={1}
                        style={{ color: "transparent", "margin-left": "20px" }}
                        src={githubIcon}
                      />
                    </a>
                    */}
                  </div>
                  {/*<p className="styles_banner_caption__OiBYq">DATAFORTRESS NAME:</p>*/}
                  <div className="styles_launch__piPr_">
                    <a
                      target="_blank"
                      className="align-left"
                      href="https://docs.stacknova.ai/"
                    >
                      <div className="styles_banner__ib2vN styles_custom_banner__Zjm0K">
                        <div className="styles_banner_button__jpdlt">
                          <div className="styles_wrapper__rvxNW">
                            <img
                              alt="logo"
                              loading="lazy"
                              decoding="async"
                              data-nimg={1}
                              className="styles_wrapper_image__Rr9s_"
                              style={{ color: "transparent" }}
                              src={stacknovaLogoWhite}
                            />
                          </div>
                          <span className="styles_wrapper_underline_text__JiPO7">
                            {t("homePage.banner.documentationButtonText")}
                          </span>
                        </div>
                        <img
                          alt="underline"
                          loading="lazy"
                          width={164}
                          height={9}
                          decoding="async"
                          data-nimg={1}
                          className="styles_banner_underline__dt2qf"
                          style={{ color: "transparent" }}
                          src={barIcon}
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt34 AppContainer_AppContainer__zMOpX max-width  PartnerSection_PartnerSection__QX3xC">
          <div className="SectionHeader_SectionHeader__mzGAY PartnerSection_sectionHeader__QYsEV">
            <div className="SectionHeaderV2_sectionHeaderSubtitle__eUmZ_">
              {t("homePage.banner.poweredBy")}
            </div>
          </div>

          <Marquee direction="right">
            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block">
                <img
                  src={openAi}
                  loading="lazy"
                  alt="Binance Labs Logo"
                  className="logotype"
                />
              </p>
            </div>

            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block">
                <img
                  src={stacks}
                  loading="lazy"
                  alt="web3 foundation"
                  className="logotype"
                />
              </p>
            </div>

            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block">
                <img
                  src={bitcoin}
                  loading="lazy"
                  alt="Bitcoin"
                  className="logotype"
                />
              </p>
            </div>

            <div className="icon-container-alt">
              <a target="_blank" className="logos__link w-inline-block h50">
                <img src={aws} loading="lazy" alt="AWS" className="logotype" />
              </a>
            </div>

            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block">
                <img
                  src={metamask}
                  loading="lazy"
                  alt="Metamask"
                  className="logotype"
                />
              </p>
            </div>

            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block">
                <img
                  src={trustwallet}
                  loading="lazy"
                  alt="Trust-Wallet"
                  className="logotype"
                />
              </p>
            </div>

            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block">
                <img
                  src={walletconnect}
                  loading="lazy"
                  alt="WalletConnect"
                  className="logotype"
                />
              </p>
            </div>

            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block">
                <img
                  src={unisat}
                  loading="lazy"
                  alt="Binance Labs Logo"
                  className="logotype"
                />
              </p>
            </div>

            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block h50">
                <img
                  src={okx}
                  loading="lazy"
                  alt="web3 foundation"
                  className="logotype"
                />
              </p>
            </div>

            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block">
                <img
                  src={leather}
                  loading="lazy"
                  alt="Leather"
                  className="logotype"
                />
              </p>
            </div>

            <div className="icon-container-alt">
              <p target="_blank" className="logos__link w-inline-block">
                <img
                  src={xverse}
                  loading="lazy"
                  alt="Xverse"
                  className="logotype"
                />
              </p>
            </div>
          </Marquee>
        </div>

        {/*  */}

        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "22%",
            bottom: "59%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "242s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "22%",
            bottom: "59%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "242s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "135%",
            bottom: "84%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "130s, 6s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "11%",
            bottom: "3%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "341s, 10s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "104%",
            bottom: "30%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "346s, 5s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            roght: "17%",
            bottom: "55%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "302s, 8s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "109%",
            bottom: "9%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "161s, 12s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "23%",
            bottom: "76%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "267s, 13s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "21%",
            bottom: "47%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "180s, 12s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "21%",
            bottom: "47%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "180s, 12s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "2%",
            bottom: "72%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "199s, 12s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "135%",
            bottom: "55%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "213s, 5s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "16%",
            bottom: "51%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "272s, 10s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "74%",
            bottom: "91%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "126s, 6s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "24%",
            bottom: "32%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "136s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "111%",
            bottom: "23%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "213s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "6%",
            bottom: "98%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "225s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "86%",
            bottom: "12%",
            width: 18,
            height: 18,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "211s, 10s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "3%",
            bottom: "40%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "318s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "3%",
            bottom: "40%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "318s, 9s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "111%",
            bottom: "60%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "306s, 11s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "20%",
            bottom: "74%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "344s, 7s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "21%",
            bottom: "82%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "234s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "5%",
            bottom: "69%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "210s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "33%",
            bottom: "88%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "141s, 4s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "10%",
            bottom: "11%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "279s, 13s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "77%",
            bottom: "14%",
            width: 24,
            height: 24,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "312s, 11s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "5%",
            bottom: "57%",
            width: 30,
            height: 30,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "262s, 5s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "25%",
            bottom: "80%",
            width: 30,
            height: 30,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "284s, 8s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            left: "21%",
            bottom: "53%",
            width: 30,
            height: 30,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "134s, 7s",
            zIndex: 90,
          }}
        />
        <img
          className="star absolute hero-star"
          src="https://cdn-www.dora.run/__dora__/morpheus/static/images/ai/bg-star.png"
          style={{
            right: "37%",
            bottom: "88%",
            width: 30,
            height: 30,
            animationName: "star-move, star-loop",
            animationIterationCount: "infinite, infinite",
            animationTimingFunction: "linear, linear",
            animationDuration: "178s, 12s",
            zIndex: 90,
          }}
        />

        {/*  */}
      </div>
      <Tooltip id="my-tooltip" />
    </>
  );
};

export default Banner;
