import { combineReducers } from "redux";
import jwtReducer from "./jwtSlice";
import amountsReducer from "./amountsSlice";
import balanceReducer from "./balanceSlice";
import blurReducer from "./blurSlice";
import dollarReducer from "./dollarSlice";
import currencyReducer from "./currencySlice";
import referralRewardsSliceReducer from "./referralRewardsSlice";
import referrerReducer from "./referrerSlice";
import referralReducer from "./referralSlice";
import buyWithCardReducer from "./buyWithCardSlice";
import dollarCardReducer from "./dollarCardSlice";
import modalReducer from "./modalSlice";
import novaPointsReducer from "./novaPointsSlice";
import referralCountReducer from "./referralCountSlice";
import totalSnovaTokensReducer from "./totalSnovaTokensSlice";
import totalSnovaValueReducer from "./totalSnovaValueSlice";
import transactionsReducer from "./transactionsSlice";
import totalNovaPointsReducer from "./totalNovaPointsSlice";
import totalReferralRewardsReducer from "./totalReferralRewardsSlice";
import pricesReducer from "./pricesSlice";
import userRankingByRewardsReducer from "./userRankingByRewardsSlice";
import userRankingByNovaPointsReducer from "./userRankingByNovaPointsSlice";
import approvalReducer from "./approvalSlice";
import modal3Reducer from "./modal3Slice";
import snovaDataReducer from "./snovaDataSlice";
import currencyBalancesReducer from "./currencyBalancesSlice";

const rootReducer = combineReducers({
  jwt: jwtReducer,
  amounts: amountsReducer,
  balance: balanceReducer,
  blur: blurReducer,
  dollar: dollarReducer,
  currency: currencyReducer,
  referralRewards: referralRewardsSliceReducer,
  referrer: referrerReducer,
  referral: referralReducer,
  buyWithCard: buyWithCardReducer,
  dollarCard: dollarCardReducer,
  modals: modalReducer,
  novaPoints: novaPointsReducer,
  referralCount: referralCountReducer,
  totalSnovaTokens: totalSnovaTokensReducer,
  totalSnovaValue: totalSnovaValueReducer,
  transactions: transactionsReducer,
  totalNovaPoints: totalNovaPointsReducer,
  totalReferralRewards: totalReferralRewardsReducer,
  prices: pricesReducer,
  userRankingByRewards: userRankingByRewardsReducer,
  userRankingByNovaPoints: userRankingByNovaPointsReducer,
  approval: approvalReducer,
  modal3: modal3Reducer,
  snovaData: snovaDataReducer,
  currencyBalances: currencyBalancesReducer,
});

export default rootReducer;
